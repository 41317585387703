import { QPagination as _component_q_pagination } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container flex-vgap" }
const _hoisted_2 = { class: "grid" }

import { computed } from 'vue'
import { APP_DESC, NUM_MEMES_PER_PAGE } from '@/common/Constants'
import { useMemeTemplateStore } from '@/web/client/store/MemeTemplate/useMemeTemplateStore'
import { getImageAsset } from '@/web/client/utils/getImageAsset'
import { useSeoMeta } from '@unhead/vue'
import { usePagination } from '@/web/client/utils/usePagination'
import { HolograResponse } from '@/web/server/interfaces/Responses/HolograResponse'
import GridCard from './GridCard.vue'
import { RouteName } from '@/web/client/router/routes'

const pageTitle = 'HoloGra Screencaps'

export default /*@__PURE__*/_defineComponent({
  __name: 'GridHologra',
  setup(__props) {

const memeTemplateStore = useMemeTemplateStore()
const { currentPage, onPaginationChange, maxPages, updateMaxPage } = usePagination(NUM_MEMES_PER_PAGE)
updateMaxPage(memeTemplateStore.hologras.length)
const pageHologras = computed<Array<HolograResponse>>(() => {
    const offset = (currentPage.value - 1) * NUM_MEMES_PER_PAGE
    return memeTemplateStore.hologras.slice(offset, offset + NUM_MEMES_PER_PAGE)
})

const title = computed(() => currentPage.value > 1 ? `${pageTitle} | Page ${currentPage.value}` : pageTitle)
const ogImage = getImageAsset('yametekudastop.png')
useSeoMeta({
    title,
    description: APP_DESC,
    ogImage,
    twitterCard: 'summary_large_image',
})

return (_ctx: any,_cache: any) => {
  

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageHologras.value, (video) => {
        return (_openBlock(), _createBlock(GridCard, {
          key: video.ytbVideoId,
          label: video.title,
          tooltip: video.title,
          route: {
                    name: _unref(RouteName).GridHolograVideo,
                    params: {
                        ytbVideoId: video.ytbVideoId,
                    },
                },
          image: video.cardUrl,
          "aspect-ratio": 1.618,
          "show-download": false
        }, null, 8, ["label", "tooltip", "route", "image"]))
      }), 128))
    ]),
    (_unref(maxPages) > 0)
      ? (_openBlock(), _createBlock(_component_q_pagination, {
          key: 0,
          modelValue: _unref(currentPage),
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : null)),
            _unref(onPaginationChange)
          ],
          max: _unref(maxPages),
          "max-pages": 5,
          "direction-links": "",
          "boundary-links": ""
        }, null, 8, ["modelValue", "max", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})